import React, { createContext, useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Modal } from "../../../organisms/components";
import { DeactivatedUser } from "../../../organisms/components";
import { NotMemberUser } from "../../../organisms/components";
import { useIsGuestUserLazyQuery } from "../../../generated/graphql";

export const isGuestContext = createContext<{
  isGuest: boolean;
}>({ isGuest: false });

export interface IsGuestProps {
  props: any;
}

export function IsGuest(props: any) {
  const [isGuest, setIsGuest] = useState<boolean>(false);
  const [isDeactivated, setIsDeactivated] = useState<boolean>(false);
  const [isCastMember, setIsCastMember] = useState<boolean>(true);

  const [getIsGuestOrMember, { data, error, loading, refetch }] =
    useIsGuestUserLazyQuery({
      onCompleted: (data) => {
        const isGuestUser =
          data?.accountAndUserDetailsScreen?.userProfile?.isGuestUser || false;

        const isDeactivatedUser =
          data?.accountAndUserDetailsScreen?.userProfile?.isDeactivatedUser ||
          false;

        const isCastMember =
          data?.accountAndUserDetailsScreen?.userProfile?.isCastMember || false;

        setIsGuest(isGuestUser);
        setIsDeactivated(isDeactivatedUser);
        setIsCastMember(isCastMember);

        // if (isDeactivatedUser) {
        //   navigate("/deactivated-user");
        // }
      },
      onError: (data) => {
        refetch();
      },
    });

  useEffect(() => {
    if (!/login/g.test(window.location.href)) {
      refetch();
    }
  }, []);

  if (loading) return null;

  return (
    <isGuestContext.Provider value={{ isGuest: isGuest }}>
      <Modal isOpen={isDeactivated} disableCloseButton={true}>
        <DeactivatedUser />
      </Modal>
      {/* Disable muna for buribooks */}
      {/* <Modal isOpen={!isCastMember} disableCloseButton={true}>
        <NotMemberUser />
      </Modal> */}
      {props.children}
    </isGuestContext.Provider>
  );
}
